<template>
  <div>
    <div class="">
        <div class="upload-box" v-if="pageType !== 'view' && pageType !== 'review'">
          <input class="upload-image" type="file" @change="uploadFile" />
          <i class="el-icon-picture-outline">Upload</i>
        </div>
        <div class="img-box">
          <el-image
            class="is-img"
            v-loading="imgLoading"
            :src="bannerPathVal"
            @load="loadImg"
          >
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
      </div>
  </div>
</template>

<script>
import AWS from '@/utils/aws-config'
export default {
  props: {
    bannerPath: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imgLoading: false
    }
  },
  computed: {
    bannerPathVal: {
      get() {
        return this.bannerPath
      },
      set(val) {
        this.$emit('update:bannerPath', val)
      }
    }
  },
  methods: {
    loadImg() {
      this.imgLoading = false
    },
    uploadFile(event) {
      this.imgLoading = true
      const file = event.target.files[0]
      if (file) {
        const s3 = new AWS.S3()
        const params = {
          Bucket: 'hkbox-test',
          Key: file.name,
          Body: file
          // ACL: 'public-read' // 设置文件访问权限
        }
        s3.upload(params, (err, data) => {
          if (err) {
            console.error(err)
          } else {
            console.log('File uploaded successfully:', data.Location)
            this.bannerPathVal = data.Location
          }
        })
      }
      console.log(this.bannerPathVal)
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-box {
  position: relative;
  width: 130px;
  height: 40px;

  .upload-image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    cursor: pointer;
  }
  .el-icon-picture-outline {
    padding: 5px;
    position: absolute;
    font-size: 20px;
    cursor: pointer;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 5px;
    border: 1px solid #cbcbcb;
  }
}
.img-box {
  width: 80%;
  height: 250px;
  border: 1px solid #cbcbcb;
  border-radius: 5px;
  // overflow: hidden;
  .is-img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
    // object-position: center;
  }
}
</style>
